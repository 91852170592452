<template>
  <div style="padding: 10px">
    <!--功能区域-->
    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd" :loading="addClientBtnLoading">新增员工</el-button>

        <el-input v-model="search" placeholder="请输入用户名称进行查询" style="width: 300px; margin-left: 800px" clearable></el-input>
        <el-button type="primary" style="margin-left: 5px" :loading="page_loading" @click="load">查询</el-button>

    </div>
    <el-table
            :data="tableData"
            v-loading="page_loading"
            border
            stripe
            row-key="id"
            lazy
            default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            style="width: 100%">
      <el-table-column
              prop="user_num"
              label="员工账号">
      </el-table-column>
      <el-table-column
              prop="user_name"
              label="员工名称">
      </el-table-column>
      <el-table-column
              prop="team.team_name"
              label="员工所属团队">
      </el-table-column>


      <el-table-column label="员工权限">
        <template #default="scopes">
          <el-tag type="danger" v-if="scopes.row.roles[0].code=='ROLE_ADMIN'" style="width: 80px;text-align:center;">{{scopes.row.roles[0].role_name}}</el-tag>
          <el-tag type="warning" v-else-if="scopes.row.roles[0].code == 'ROLE_REGION'" style="width: 80px;text-align:center;">{{scopes.row.roles[0].role_name}}</el-tag>
          <el-tag v-else-if="scopes.row.roles[0].code == 'ROLE_TEAM'" style="width: 80px;text-align:center;">{{scopes.row.roles[0].role_name}}</el-tag>
          <el-tag type="info" v-else-if="scopes.row.roles[0].code == 'ROLE_STAFF'"  style="width: 80px;text-align:center;">{{scopes.row.roles[0].role_name}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="260">
        <template #default="scope">
          <el-button size="mini" type="primary" plain @click="handleEdit(scope.row)"
                     :loading="scope.row.editClientBtnLoading">编辑</el-button>

          <el-popconfirm v-if="User.roles[0].id<scope.row.roles[0].id" title="确定删除吗？" @confirm="handleDelete(scope.row)">
            <template #reference>
              <el-button size="mini" type="danger" :loading="scope.row.deleteClientBtnLoading">删除</el-button>
            </template>
          </el-popconfirm>

          <el-popconfirm v-if="User.roles[0].id<scope.row.roles[0].id" title="确定重置密码为(123456)吗？" @confirm="reset_password(scope.row)">
            <template #reference>
              <el-button size="mini" type="warning" :loading="scope.row.resetPasswordBtnLoading">重置密码</el-button>
            </template>
          </el-popconfirm>
        </template>

      </el-table-column>
    </el-table>
    <div style="margin: 10px 0">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>

      <!--新增弹窗-->
      <el-dialog title="新增员工" v-model="dialogVisible" width="30%" :before-close="add_user_handleClose">
        <el-form :model="add_user_form" ref="add_user_form" label-width="120px" :rules="add_rules" >
          <el-form-item label="账号" prop="user_num">
            <el-input v-model="add_user_form.user_num" style="width: 75%" placeholder="请输入账号"></el-input>
          </el-form-item>

          <el-form-item label="名称" prop="user_name">
            <el-input v-model="add_user_form.user_name" style="width: 75%" placeholder="请输入用户名称"></el-input>
          </el-form-item>

          <el-form-item label="权限等级" prop="role_code">
            <el-radio v-if="User.roles[0].id<=1" v-model="add_user_form.role_code" label="ROLE_REGION">地区管理员</el-radio>
            <el-radio v-if="User.roles[0].id<=2" v-model="add_user_form.role_code" label="ROLE_TEAM">团队管理员</el-radio>
            <el-radio v-if="User.roles[0].id<=3" v-model="add_user_form.role_code" label="ROLE_STAFF" >员工</el-radio>
          </el-form-item>

          <el-form-item label="密码" prop="user_password">
            <el-input v-model="add_user_form.user_password" style="width: 75%" placeholder="请输入密码" show-password></el-input>
          </el-form-item>

          <el-form-item label="分机号码" prop="user_ext_num">
            <el-input v-model="add_user_form.user_ext_num" style="width: 75%" placeholder="请输入分机号码" ></el-input>
          </el-form-item>

          <el-form-item label="分机密码" prop="user_ext_password">
            <el-input v-model="add_user_form.user_ext_password" style="width: 75%" placeholder="请输入分机密码" show-password></el-input>
          </el-form-item>

          <el-form-item label="所属团队" prop="team">
            <el-select v-model="add_user_form_team_index" placeholder="请选择团队" style="width: 75%" :disabled="teamAble">
              <el-option
                      v-for="(item,index) in team_info"
                      :key="item.team_id"
                      :label=" '(' + item.team_id + ')' +  item.team_name "
                      :value="index">
                <span style="float: left">{{ item.team_id }}</span>
                <span style="float: right; color: var(--el-text-color-secondary);">{{ item.team_name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="add_user" :loading="addClientOKBtnLoading">确 定</el-button>
          </span>
        </template>
      </el-dialog>


      <!--编辑弹窗-->
      <el-dialog title="编辑信息" v-model="EditVisible" width="30%">
        <el-form model="edit_user_form" label-width="120px" :rules="edit_rules">
          <el-form-item label="员工账号">
            <el-input :disabled="User.roles[0].id>=edit_user_form.roles[0].id" v-model="edit_user_form.user_num" style="width: 75%"></el-input>
          </el-form-item>

          <el-form-item label="员工名称">
            <el-input :disabled="User.roles[0].id>=edit_user_form.roles[0].id" v-model="edit_user_form.user_name" style="width: 75%"></el-input>
          </el-form-item>
          <el-form-item label="用户权限" prop="role_code">
            <el-radio :disabled="true"  v-model="edit_user_form.roles[0].code" label="ROLE_ADMIN">最高管理员</el-radio>
            <el-radio :disabled="User.roles[0].id>=edit_user_form.roles[0].id||User.roles[0].id>1"  v-model="edit_user_form.roles[0].code" label="ROLE_REGION">地区管理员</el-radio>
            <el-radio :disabled="User.roles[0].id>=edit_user_form.roles[0].id||User.roles[0].id>2"  v-model="edit_user_form.roles[0].code" label="ROLE_TEAM">团队管理员</el-radio>
            <el-radio :disabled="User.roles[0].id>=edit_user_form.roles[0].id||User.roles[0].id>3"  v-model="edit_user_form.roles[0].code" label="ROLE_STAFF" >员工</el-radio>
          </el-form-item>

          <el-form-item label="所属团队">
            <el-select v-model="edit_user_form_team_index" placeholder="请选择团队" style="width: 75%"
                       :disabled="User.roles[0].id>=edit_user_form.roles[0].id||teamAble">
              <el-option
                      v-for="(item,index) in team_info"
                      :key="item.team_id"
                      :label=" '(' +item.team_id + ')' +  item.team_name "
                      :value="index">
                <span style="float: left">{{ item.team_id }}</span>
                <span style="float: right; color: var(--el-text-color-secondary);">{{ item.team_name }}</span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="分机号码">
            <el-input :disabled="User.roles[0].id>=edit_user_form.roles[0].id" v-model="edit_user_form.user_ext_num" style="width: 75%"></el-input>
          </el-form-item>

          <el-form-item label="分机密码">
            <el-input :disabled="User.roles[0].id>=edit_user_form.roles[0].id" type="password"  v-model="edit_user_form.user_ext_password" style="width: 75%"></el-input>
          </el-form-item>

        </el-form>
        <template #footer>
                  <span class="dialog-footer">
                    <el-button @click="EditVisible = false">取 消</el-button>
                    <el-button type="primary" @click="edit_user" :loading="editClientOKBtnLoading">确 定</el-button>
                  </span>
        </template>
      </el-dialog>

    </div>
  </div>

</template>

<script>
// @ is an alias to /src


import request from "../../utils/request";

export default {
  name: 'Home',
  data(){
    return{
      add_user_form:{

      },
      dialogVisible:false,//弹窗默认是关闭的
      EditVisible:false, //默认关闭
      search:'',
      total: 1,
      currentPage: 1,
      page_loading:false,
      pageSize:10,
      tableData: [],
      User:{},
      edit_user_form:{},//编辑弹框表单
      team_info:{},
	  teamAble:false,
      reset:{},//重置密码用
      addClientBtnLoading:false,
      addClientOKBtnLoading:false,
      editClientOKBtnLoading:false,
      add_user_form_team_index:0,
      edit_user_form_team_index:0,

	  add_rules: {
	      user_num: [
	        {required: true, message: '请输入账号',trigger:'blur'},
	      ],
	      user_name: [
	        {required: true, message: '请输入用户名称',trigger:'blur'},
	      ],
	      role: [
	        {required: true, message: '请选择用户权限',trigger:'change'},
	      ],
	      user_password: [
	        {required: true, message: '请输入密码',trigger:'blur'},
	      ],
	      /*team: [
	        {required: true, message: '请选择用户团队',trigger:'change'},
	      ],*/
	  },
    }
  },
  created() {
    this.load()
  },
  methods:{
    load() {

      this.page_loading = true
      let userJSON = sessionStorage.getItem("user")
	  this.User=JSON.parse(userJSON)
      request.post("/user"+'?pageNum='+this.currentPage+'&pageSize='+this.pageSize+'&search='+this.search).then(res => {
        if(res.code===200){

          this.tableData = res.data.list //接收到的数据
          this.total = res.data.total
        }else{
          this.$message({
            type:"error",
            message:res.msg,
          })
        }
        this.page_loading = false
      }).catch(e=>{
        this.page_loading = false
        this.$message({
          type:"error",
          message:'查询失败,服务器错误',
        })
      })
    },
    handleAdd(){
	  if(this.User.roles[0].id==3){
		  this.teamAble=true;
	  }
	  this.addClientBtnLoading=true
      request.post("/team").then(res => { //获取团队信息
        if(res.code===200){
          this.team_info = res.data//所有团队的信息存在info里  用于下拉框选择
          for(var i=0;i<this.team_info.length;i++){
            if(this.team_info[i].team_id==this.User.team.team_id){
              this.add_user_form_team_index=i;
              break;
            }
          }
          this.add_user_form={};
          this.add_user_form.role_code="ROLE_STAFF";
          this.dialogVisible=true;
        }else{
          this.$message({
            type: "error",
            message: res.msg
          })
        }
        this.addClientBtnLoading=false

      }).catch(e=>{
        this.addClientBtnLoading=false
        this.$message({
          type:"error",
          message:'请求失败,服务器错误',
        })
      })


    },
    add_user(){//新增员工提交数据
      this.$refs['add_user_form'].validate((valid) => {
        if(valid){
          this.add_user_form.team=this.team_info[this.add_user_form_team_index]
          this.addClientOKBtnLoading=true
          this.add_user_form.roles=[]
          var role={}
          role.code=this.add_user_form.role_code
          this.add_user_form.roles[0]=role

          request.post("/user/add",this.add_user_form).then(res =>{
            if(res.code===200){
              this.$message({
                type:"success",
                message:"新增成功"
              })
              this.dialogVisible = false  // 关闭弹窗
              this.add_user_form={}
              this.load() // 刷新表格的数据
            }
            else{
              this.$message({
                type:"error",
                message:res.msg
              })
            }
            this.addClientOKBtnLoading=false
          }).catch(e=>{
            this.addClientOKBtnLoading=false
            this.$message({
              type:"error",
              message:'请求失败,服务器错误',
            })
          })
        }
      })


    },
    add_user_handleClose(){
      this.$refs['add_user_form'].resetFields()
      this.dialogVisible=false
    },
    edit_user(){
      this.edit_user_form.team=this.team_info[this.edit_user_form_team_index]
      this.editClientOKBtnLoading=true
      request.post("/user/change",this.edit_user_form).then(res =>{
        if(res.code===200){
          this.$message({
            type:"success",
            message:"编辑成功"
          })
          this.EditVisible = false  // 关闭弹窗
          this.load() // 刷新表格的数据
          // this.edit_user_form={}//更新完了置为空
        }
        else{
          this.$message({
            type:"error",
            message:res.msg,
          })
        }
        this.editClientOKBtnLoading=false

      }).catch(e=>{
        this.editClientOKBtnLoading=false
        this.$message({
          type:"error",
          message:'请求失败,服务器错误',
        })
      })

    },
    handleEdit(row){
      if(this.User.roles[0].id==3){
        this.teamAble=true;
      }
      this.edit_user_form = JSON.parse(JSON.stringify(row))//深拷贝
      row.editClientBtnLoading=true
      request.post("/team").then(res => { //获取团队信息
        if(res.code===200){
          this.team_info = res.data//所有团队的信息存在info里  用于下拉框选择
          for(var i=0;i<this.team_info.length;i++){
            if(this.team_info[i].team_id==row.team.team_id){
              this.edit_user_form_team_index=i;
              break;
            }
          }
          this.EditVisible = true
          //this.edit_user_form.user_level=row.user_level+''
        }else{
          this.$message({
            type:"error",
            message:res.msg,
          })
        }
        row.editClientBtnLoading=false
      }).catch(e=>{
        row.editClientBtnLoading=false
        this.$message({
          type:"error",
          message:'请求失败,服务器错误',
        })
      })

    },
    handleDelete(row){
      var user_id=row.user_id
      row.deleteClientBtnLoading=true
      request.post("/user/delete/" + user_id).then(res =>{
        if(res.code===200){
          this.$message({
            type:"success",
            message:"删除成功"
          })
          this.load()
        }
        else{
          this.$message({
            type:"error",
            message:res.msg
          })
        }
        row.deleteClientBtnLoading=false

      }).catch(e=>{
        row.deleteClientBtnLoading=false
        this.$message({
          type:"error",
          message:'请求失败,服务器错误',
        })
      })

    },
    handleSizeChange(pageSize){//改变当前每一页的个数触发
      this.pageSize=pageSize
      this.load();
    },
    handleCurrentChange(pageNum){//改变当前页码触发
      this.currentPage=pageNum
      this.load();
    },
    reset_password(row){
      this.reset=JSON.parse(JSON.stringify(row))
      row.resetPasswordBtnLoading=true
      request.post("/user/reset/"+this.reset.user_id).then(res =>{
        if(res.code===200){
          this.$message({
            type:"success",
            message:"重置成功"
          })
          this.load() // 刷新表格的数据
        }
        else{
          this.$message({
            type:"error",
            message:res.msg,
          })
        }
        row.resetPasswordBtnLoading=false

      }).catch(e=>{
        row.resetPasswordBtnLoading=false
        this.$message({
          type:"error",
          message:'请求失败,服务器错误',
        })
      })


    }
  },
  components: {

  }
}
</script>
